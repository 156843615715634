import React from 'react';
import './Home.css';

// TODO: Generalize import and animation transition 
import p1 from '../../../media/panorama/p1.jpg';
import p2 from '../../../media/panorama/p2.jpg';
import p3 from '../../../media/panorama/p3.jpg';
import p4 from '../../../media/panorama/p4.jpg';
import p5 from '../../../media/panorama/p5.jpg';
import p6 from '../../../media/panorama/p6.jpg';

import btnEditions from '../../../media/images/btn_editions.jpg';

import Footer from '../../structure/Footer/Footer';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="Page">
            {/* Home header */}
            <header className='headerPanorama'>
                <nav className='navPanorama'>
                    <Link to='/editions'>&nbsp;Editions Kallimages&nbsp;|&nbsp;</Link>
                    <Link to="/about">Qui sommes-nous&nbsp;|&nbsp;</Link>
                    <Link to="/contact">Contact</Link>
                </nav>
                <div className="panorama" x-style={{ display: 'none' }}>
                    <img src={p1} alt="panorama1" />
                    <img src={p2} alt="panorama2" />
                    <img src={p3} alt="panorama3" />
                    <img src={p4} alt="panorama4" />
                    <img src={p5} alt="panorama5" />
                    <img src={p6} alt="panorama6" />
                </div>
            </header>

            {/* Home Main */}
            <main>
                <Link to='/editions'>
                    <div className="editions">
                        <img src='/res/editions/23.jpg' alt="Voir les éditions" width="350" />
                        <img src={btnEditions} alt="Voir les éditions" width="200" />
                        <p>
                            Entrez
                        </p>
                    </div>
                </Link>
            </main>

            {/* Common Footer */}
            <Footer></Footer>
        </div>
    )
}

export default Home;