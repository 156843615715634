import React from 'react';
import Footer from '../../structure/Footer/Footer';
import Header from '../../structure/Header/Header';
import './About.css';

import flagEn from '../../../media/images/flag_en.gif';
import flagFr from '../../../media/images/flag_fr.gif';
import flagGr from '../../../media/images/flag_gr.gif';

function About() {
  return (
    <div className="Page">
      <Header></Header>
      <div className="About">
        <div className="description fr">
          <div className="flag">
            <img src={flagFr} alt="flag-fr" />
          </div>          <p>
            Les Éditions Kallimages, créées en septembre 2004 à Paris, ont pour vocation la publication, en France et dans les pays francophones, de livres d’art illustrés de photographies anciennes sur le monde hellénique et plus généralement sur les pays riverains de la Méditerranée.
          </p>

          <p>
            Les publications sont organisées sur le thème du voyage, et proposent un itinéraire visuel relatif à un lieu géographique, à un sujet, à un événement. Le but de ces ouvrages est non seulement de montrer des photographies mais aussi de les replacer dans leur contexte – historique ou artistique – en les mettant en parallèle avec des relations de voyages – réels ou fictifs – publiées ou inédites ou bien encore avec des textes contemporains dans lesquels l'auteur partage son émotion avec le lecteur.
          </p>
        </div>
        <hr />
        <div className="description gr">
          <div className="flag">
            <img src={flagGr} alt="flag-gr" />
          </div>
          <p>
          Οι Εκδόσεις Kallimages (οι Ωραίες Εικόνες), δημιουργηθήκαν το 2004 στο Παρίσι. Έχουν σαν αποστολή την δημοσίευση γαλλόφωνων αλλά και δίγλωσσων (γαλλο-ελληνικών) βιβλίων τέχνης με θέμα τον ελληνισμό και γενικότερα τη Μεσόγειο και τις χώρες που την περιβάλλουν.
Η μοναδικότητα των βιβλίων τους βρίσκεται στην ιδιαίτερα επιλεγμένη ποιότητα της εικονογράφησης που χρησιμοποιούν, τη φωτογραφία αυτήν την παγκόσμια γλώσσα επικοινωνίας. Η κύρια πηγή των εικόνων αντλείται κυρίως από το μεγάλο και σπάνιο πλούτο της Φωτοθήκης Kallimages, με πέρα από πενήντα χιλιάδες ταξινομημένες φωτογραφίες, οι οποίες ξεκινάνε χρονολογικά από το 1839 και φθάνουν έως και τις μέρες μας.
          </p>

          <p>
          Τα βιβλία των Εκδόσεων Kallimages έχουν σαν άξονα το ταξίδι, προτείνοντας ένα γεωγραφικό - οπτικό οδοιπορικό σχετικά με ένα θέμα ή ένα γεγονός. Ο στόχος των εκδόσεων τους δεν είναι μόνο να παρουσιάσουν παλαιές και σύγχρονες φωτογραφίες, αλλά και να τις κατατάξουν μέσα στο όλο πλαίσιο, ιστορικό και καλλιτεχνικό. Επιδιώκεται ένας παραλληλισμός εικόνων και ταξιδιωτικών εντυπώσεων – πραγματικών ή ονειρεμένων –, δημοσιευμένων ή ανέκδοτων, ή ακόμη εμπνευσμένων από σύγχρονους συγγραφείς, οι οποίοι καλούνται να μοιραστούν εκφραστικά, την συγκίνηση τους για τη φωτογραφία και την μαγεία της εικόνας, μαζί με τον απαιτητικό αναγνώστη.
          </p>
        </div>
        <hr />
        <div className="description en">
          <div className="flag">
            <img src={flagEn} alt="flag-en" />
          </div>          <p>
          Established in 2004 in Paris, the Éditions Kallimages aim to publish, in France and French speaking countries, art books illustrated with ancient photographs of the Hellenic world and more generally of the countries bordering the Mediterranean Sea. Photography being a universal language of communication the Éditions Kallimages select rigorously the iconography in each of their books. It is their first specificity. Their principal source is the rich Photothèque Kallimages which consists of more than 50 000 images dating from 1839 until today.
          </p>

          <p>
          The publications are organized according to the theme of travel and offer a visual itinerary concerning a place, a subject or an event. So, these books not only show photographs but place them in their historical and artistic context. They draw a parallel with real or fictitious accounts of travel, published or not, or with contemporary texts in which the author shares his impression and emotion with the reader.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default About;
