import React from 'react';
import parse from 'html-react-parser'
import Footer from '../../structure/Footer/Footer';
import Header from '../../structure/Header/Header';
import './Edition.css';

import { books } from '../Editions/books';
import { Link, useParams } from 'react-router-dom';

function Edition() {
  const { id } = useParams();
  const book = books.find((book) => book.id === parseInt(id!))!;

  return (
    <div className="Page">
      <Header></Header>
      <div className="Edition">
        <p className='linkBackToEditions'>
          <Link to='/editions'>Liste des livres</Link>
        </p>
        <table>
          <tbody>
            <tr>
              <td width='300' valign='top'>
                <div className="cover">
                  <img src={'/res/editions/' + book.cover} alt='book cover' />
                </div>
                <div className="characteristics">
                  <p>
                    {parse(book.caracteristics)}
                  </p>
                  <p>
                    Participation aux frais d'envoi / Συμμετοχή στα ταχυδρομικά έξοδα / Participation shipping : 3€
                  </p>
                  <p>
                    Envoi partout dans le monde / Αποστολή σε όλο τον κόσμο / Sending around the world
                  </p>
                </div>
                <div className="price">
                  Prix: {book.price}
                </div>
              </td>
              <td width='400' valign='top' className='right'>
                <div className="titleDetail">
                  <b>{parse(book.titleDetail)}</b>
                  {book.subtitleDetail ? <em className='subtitleDetail'>, {book.subtitleDetail}</em> : ''}
                </div>
                <p className='authors'>
                  {parse(book.authors)}
                </p>
                <div className="description">
                  {parse(book.description)}
                </div>
                <p className='preview'>
                  <Link to={'/preview/' + book.id} className="previewLink">Feuilleter le livre</Link>
                </p>
                <p className='paypal'>
                  {parse(book.paypal)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Edition;
