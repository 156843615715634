import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';

import Home from './components/pages/Home/Home';
import Editions from './components/pages/Editions/Editions';
import About from './components/pages/About/About';
import Contact from './components/pages/Contact/Contact';
import Edition from './components/pages/Edition/Edition';
import EditionPreview from './components/pages/EditionPreview/EditionPreview';

function App() {
  return (
    <Routes>
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/edition/:id" element={<Edition />} />
      <Route path="/editions" element={<Editions />} />
      <Route path="/preview/:id" element={<EditionPreview />} />
      <Route path="/" element={<Home />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default App;
