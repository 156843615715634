import React from 'react';
import Footer from '../../structure/Footer/Footer';
import Header from '../../structure/Header/Header';
import './Editions.css';

import { books } from './books';
import { Link } from 'react-router-dom';

function Editions() {
  // const bookItems = books.sort((b1, b2) => b2.id - b1.id).map((book) =>
  const bookItems = books.map((book, idx) =>
    <div className='book' key={book.id}>
      <Link to={'/edition/' + book.id}>
        <img src={'/res/editions/' + book.cover} alt="thumbnail" />
      </Link>
      <div className='right'>
        <div className='titleList'>{book.titleList}
          {book.subtitleList ? <span className='subtitleList'>, {book.subtitleList}</span> : ''}
        </div>

        <div className='summary'>
          {book.summary}&nbsp;
          <Link to={'/edition/' + book.id}>Plus d'infos &gt;&gt;</Link>
        </div>

        <p>
          <Link to={'/preview/' + book.id} className="previewLink">Feuilleter le livre</Link>
        </p>
      </div>
    </div>
  );

  return (
    <div className="Page">
      <Header></Header>
      <div className="Editions">
        <div className="bookItems">
          {bookItems}
        </div>
        {/* <p className='bonDeCommande'>
          <a href='/res/documents/bonDeCommande.pdf' target='_blank'>Téléchargez le bon de commande</a>
        </p> */}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Editions;
