import React from 'react';
import './Header.css';

import headerImg from '../../../media/images/header1.jpg';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="Header">
      <img src={headerImg} alt="header background" />
      <Link to='/'>
        <div className='linkHome'></div>
      </Link>
      <nav className='navHeader'>
        <Link to='/'>&nbsp;Accueil&nbsp;|&nbsp;</Link>
        <Link to='/editions'>&nbsp;Editions Kallimages&nbsp;|&nbsp;</Link>
        <Link to="/about">Qui sommes-nous&nbsp;|&nbsp;</Link>
        <Link to="/contact">Contact&nbsp;&nbsp;</Link>
      </nav>
    </header>
  );
}

export default Header;