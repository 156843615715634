import React from 'react';
import './Footer.css';

function Footer() {
    return (
      <footer className="Footer">
        &copy; Kallimages | 2004-2024 | Tous droits réservés
      </footer>
    );
  }
  
export default Footer;