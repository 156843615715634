import React from 'react';
import Footer from '../../structure/Footer/Footer';
import Header from '../../structure/Header/Header';
import './Contact.css';

function Contact() {
  return (
    <div className="Page">
      <Header></Header>
      <div className="Contact">
        Editions Kallimages<br/>
        28 rue Aliénor d’Aquitaine<br/>
        19360 Malemort<br/>
        FRANCE<br/>
        <br/>
        Tél: +33 622741466<br/>
        <br/>
        email : <a href="mailto:editions.kallimages@gmail.com">editions.kallimages@gmail.com</a>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;