import React from 'react';
import parse from 'html-react-parser';
import Footer from '../../structure/Footer/Footer';

import './EditionPreview.css';

import { books } from '../Editions/books';
import { Link, useParams } from 'react-router-dom';

function EditionPreview() {
  const { id } = useParams();
  const book = books.find((book) => book.id === parseInt(id!))!;
      
  return (
    <div className="Page">
      <p className='linkBackToEditions'>
        <br/>
        <Link to='/editions'>Liste des livres</Link>
      </p>
      <div className="EditionPreview">
        <p className='paypal'>
          {parse(book.paypal)}
        </p>
        <div>
          {parse(book.preview)}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default EditionPreview;
