export const books = [
  {
    id: 1,
    thumbnail: `Olympie.jpg`,
    titleList: `D'Olympie à Athènes`,
    subtitleList: ``,
    summary: `D'Olympie à Athènes est un voyage
        imaginaire à travers le temps et l'espace
        des Jeux olympiques.`,

    cover: `OlympieBig.jpg`,
    titleDetail: `D'Olympie à Athènes`,
    subtitleDetail: `Voyage photographique sur les lieux des Jeux olympiques`,
    authors: `Par Haris Yiakoumis et Lucie Bonato`,
    description: `<span class="texte"> D'Olympie à Athènes est un voyage imaginaire à travers le temps et l'espace des Jeux olympiques, à l'aide de rares et magnifiques photographies anciennes. <br>
        <br>
        Le voyage commence en 776 av. J.-C., sur le lieu d'origine des Jeux olympiques, Olympie, pour arriver à la ville d'Athènes de 1896 à l'endroit de leur renaissance, le stade "kallimarmaro". <br>
        <br>
        En 2004, année importante pour le retour des Jeux dans leur patrie, cet ouvrage est un hommage particulier et unique puisque les héros ne sont pas seulement les athlètes, mais aussi les hommes et les femmes de Grèce qui prennent vie devant nos yeux et nous transportent dans une autre époque. </span>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <div align="center">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="9002110">
          <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
          <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" m03sgoakk="" width="1" height="1" border="0"></div>
      </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=0006748539df1fd767609" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1">Parution : Juillet 2004<br>
        Collection : <em>Album - Théma</em><br>
        Français<br>
        ISBN : 960-540-562-8<br>
        Format : 25x30 cm, 144 pages<br>
        Couverture souple avec rabat<br>
        200 photographies en quadrichromie</p>`,

    price: `35 €`
  },

  {
    id: 2,
    thumbnail: `Algerie.jpg`,
    titleList: `L’Algérie d’Edmond Sergent`,
    subtitleList: `directeur de l’Institut Pasteur d’Algérie (1912-1962)`,
    summary: `Cet ouvrage est une invitation au voyage dans l’Algérie des années 1920-1930...`,

    cover: `AlgerieBig.jpg`,
    titleDetail: `L’Algérie d’Edmond Sergent`,
    subtitleDetail: `directeur de l'Institut Pasteur d'Algérie (1912-1962)`,
    authors: `Par Jean-Pierre Dedet`,
    description: `<p style="text-align:justify">Cet ouvrage est une invitation au voyage dans l'Algérie des années 1920-1930 à travers les dessins et les photographies d’Edmond Sergent, médecin, microbiologiste, naturaliste, humaniste,
        né à Philippeville en 1876. Il se spécialisa, à partir de 1899, à l’Institut Pasteur à Paris, dans la science des microbes nouvellement découverte par Pasteur et dirigea pendant 50 ans la filiale
        pastorienne d’Alger où il réalisa, avec son frère Etienne, une œuvre exceptionnelle au service du
        développement sanitaire et social de la population.</p>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <input type="hidden" name="cmd" value="_s-xclick">
        <input type="hidden" name="hosted_button_id" value="5XZMHL4XSB3BU">
        <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus s�curis�e !" border="0">
        <img alt="" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" k8so4yh17="" width="1" height="1" border="0">
      </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=000674853c2ee0f02b01c" seamless="seamless" allowtransparency="true" width="100%" height="500" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Novembre 2010<br>
        Collection: Biographia<br>
        ISBN : 978-2-915936-11-7 <br>
        Format : 21x24 cm, 168 pages <br>
        Couverture souple avec rabat<br>
        230 photographies en couleur et N/B</p>`,

    price: `35 €`
  },

  {
    id: 3,
    thumbnail: `Bisson.jpg`,
    titleList: `Hippolyte Bisson`,
    subtitleList: ``,
    summary: `Hippolyte Bisson, né à Guémené sur les rives du Scorff en février 1796, s’engagea...`,

    cover: `BissonBig.jpg`,
    titleDetail: `Hippolyte Bisson`,
    subtitleDetail: `capitaine du Panayoti`,
    authors: `Par Maryse Emery et Gérard Guégan`,
    description: `<span class="texte"> Hippolyte Bisson, né à Guémené sur les rives du Scorff en février 1796, s'engagea dès son plus jeune âge comme mousse puis novice dans les convois côtiers de Bretagne. Il continua ensuite son apprentissage à bord du Tourville, navire-école amarré en rade de Brest. Après plusieurs campagnes dans les océans Atlantique et Indien, il fut rapidement promu au grade d'enseigne de vaisseau avant d'accomplir des missions en mer Égée et dans le Levant. <br>
        <br>
        En 1827, la France décida d'envoyer une escadre en Méditerranée afin de pourchasser les pirates qui, à la recherche de proies faciles, mettaient à mal toute espèce de commerce et terrorisaient les populations dans la plus parfaite impunité. Le 5 novembre, l'île d'Astipaléa fut le théâtre d'un geste historique dans la grande tradition maritime : Hippolyte Bisson allait incarner la forme à la fois la plus extrême et la plus controversée de l'héroïsme, celle qui consiste à donner sa vie pour la cause que l'on défend. </span>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <div align="center">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="9002091">
          <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
          <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" b03j0pjf4="" width="1" height="1" border="0"> </div>
      </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=00067485357aac9a1a7dc" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1">Parution : Mai 2007<br>
        Collection : <em>Biographia</em><br>
        Français<br>
        ISBN : 2-915936-05-6<br>
        Format : 20x24,5 cm, 160 pages<br>
        Couverture souple avec rabat<br>
        140 illustrations en quadrichromie </p>`,

    price: `25 €`
  },

  {
    id: 4,
    thumbnail: `TheogoniaCovFR.jpg`,
    titleList: `Théogonie`,
    subtitleList: `L’Aphrodite de Chypre`,
    summary: `S’inspirant de la Théogonie d’Hésiode qui décrit, entre autres, la naissance d’Aphrodite ...`,

    cover: `TheogoniaCovBig_0.jpg`,
    titleDetail: `<p><strong>Théogonie, L’Aphrodite de Chypre</strong><br><strong>Theogony, The Aphrodite of Cyprus</strong><br><strong>Θεογονία, Η Αφροδίτη της Κύπρου</strong><br><strong>ТЕОГОНИЯ, АФРОДИТА КИПРИДА</strong><br>
        </p>`,
    subtitleDetail: ``,
    authors: `Photographies: Haris Yiakoumis<br/>Introduction: Jacqueline Karageorghis`,
    description: `<p>S’inspirant de la Théogonie d’Hésiode qui décrit, entre autres, la naissance d’Aphrodite dans l’île de Chypre, ce livre, avec les photographies panoramiques de Haris Yiakoumis accompagnées d’une introduction historique de Jacqueline Karageorghis, montre d’une manière admirable Petra tou Romiou, lieu magique et mythique de la naissance de la déesse de l’amour. Il est accompagné d’un DVD d’images vidéo et du bruit des vagues de cette mer unique.</p>
        <p>Inspired by the Theogony of Hesiod that describes, among other things, the birth of Aphrodite in Cyprus, this book presents in a wonderful way Petra tou Romiou, the magical and mythical place of birth of the goddess of love, with Haris Yiakoumis’ panoramic photographs and a historical introduction by Jacqueline Karageorghis. It is accompanied by a DVD with video and the sound of the waves of this unique sea.</p>

        <p>Εμπνευσμένο από τη Θεογονία του Ησιόδου που περιγράφει, μεταξύ άλλων, τη γέννηση της Αφροδίτης στην Κύπρο, το βιβλίο αυτό, με τις πανοραμικές φωτογραφίες του Χάρη Γιακουμή και με την ιστορικά τεκμηριωμένη εισαγωγή της Ζακελίν Καραγιώργη, μας παρουσιάζει με ένα υπέροχο τρόπο την Πέτρα του Ρωμιού, μαγικό και μυθικό τόπος γέννησης της θεάς του έρωτα. Το βιβλίο συνοδεύεται από ένα DVD βίντεο και ήχο των κυμάτων αυτής της μοναδικής θάλασσας.</p>
        <p>Опираясь на Теогонию Гесиода, которая описывает, между прочим, рождение Афродиты на Кипре, эта книга с панорамными фотографиями Ариса Якумиса, сопровождёнными историческим вступлением Жаклин Карагеоргис, показывает Петра ту Ромиу, магическое и мифическое место рождения богини любви. В комплект входит также DVD с видеоизображениями и шумом волн этого уникального моря. </p>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <input type="hidden" name="cmd" value="_s-xclick">
        <input type="hidden" name="hosted_button_id" value="659WXECRCE9VY">
        <table>
          <tbody><tr>
            <td><input type="hidden" name="on0" value="Langue :">Langue : <select name="os0">
                <option value="Version française">Version française </option>
                <option value="Русская версия">Русская версия</option>
                <option value="English version">English version </option>
                <option value="Ελληνική έκδοση">Ελληνική έκδοση</option>
              </select> </td>
          </tr>
        </tbody></table>
        <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sёcurisёe !" border="0">
        <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" gwfz9i0ms="" width="1" height="1" border="0">
      </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=000674853a3315c968235&amp;view=book&amp;mode=viewer" seamless="seamless" allowtransparency="true" width="100%" height="500" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Septembre 2012<br>
        Collection: Pan-Orama<br>
        Français / English / Ελληνικά / Pусский<br>
        <strong>ISBN 978-2-915936-15-5&nbsp; Français</strong><br>
        <strong>ISBN 978-2-915936-17-9&nbsp; English</strong><br>
        <strong>ISBN 978-2-915936-16-2 Ελληνικά</strong><br>
        <strong>ISBN 978-2-915936-18-6&nbsp; Pусский</strong><br>
        Format : 19,5 x 29,5 cm, 144 pages&nbsp;<br>
        Couverture rigide / Hard cover<br>
        110 illustrations en couleur / Color illustrations</p>`,

    price: `25 €`
  },

  {
    id: 5,
    thumbnail: `MaRue.jpg`,
    titleList: `Ma rue à Famagouste`,
    subtitleList: ``,
    summary: `Je suis un numéro parmi les deux cents mille réfugiés qui ont été déportés de leurs foyers en ...`,

    cover: `MaRueBig.jpg`,
    titleDetail: `Ma rue à Famagouste`,
    subtitleDetail: `ville fantôme de Chypre`,
    authors: `Par Claire Angelides<br/>Préface par Anna Olvia Jacovides Andrieu`,
    description: `<p align="center"><strong>La rue de la ville fantôme</strong></p>
        <p dir="ltr" align="left">Je suis un numéro parmi les deux cents mille réfugiés qui ont été déportés de leurs foyers en 1974. Je porte en moi la nostalgie de ma ville bien aimée, Famagouste, la ville aux fleurs de citronniers, la dame embrassée par les vagues que je chante car elle était belle avec ses orangeraies et ses plages de sable doré, la plus belle au monde. Elle ressemblait à un petit coquillage jeté au sein du golfe de Famagouste. </p>
        <p dir="ltr" align="left">Une ville baignée par la lumière, pleine de lys de sable, de pêcheurs et de ports de pêche remplis de bateaux. Mais par-dessus tout une ville dotée d’un mouvement culturel intense avec des associations, des activités artistiques variées dans le domaine de la musique, de l’art, de la littérature. </p>
        <p dir="ltr" align="left">Un grand nombre de poètes et peintres originaires &nbsp;de Famagouste ont chanté et peint ses attraits. Elle était vraiment le joyau de Chypre. Le diamant de la Méditerranée, la dame aux fleurs des citronniers. </p>
        <p dir="ltr" align="left">À présent elle est une ville fantôme. Inhabitée depuis 1974. Tout est asséché, les arbres, les fleurs. La ville est morte. Mais nous, nous la chantons et nous attendons le jour du retour qui viendra. Nous l’attendons car la ville nous appartient. </p>
        <strong id="docs-internal-guid-41640405-bdf0-0ea8-034c-c2ca822d7c94">Claire Angelides </strong>a écrit douze recueils poétiques dont le thème principal est la réflexion sur le sort amer de l’île de Chypre. Elle est née à Famagouste et a fait ses études à l’École Philosophique de l’Université d’Athènes. Elle a travaillé comme enseignante puis proviseure dans l’Éducation du second degré. Elle a participé activement à la lutte contre l’occupation anglaise de 1955 - 1959 et a été membre &nbsp;actif de la vie sociale et intellectuelle de sa ville. En mai 1991 elle a été élue députée du parti Dimokratikos Synaguermos (DISY) et, de 1993 à 1997, elle a été ministre de l’Éducation et de la Culture pendant la présidence de Glavkos Klirides.<br>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <input type="hidden" name="cmd" value="_s-xclick">
        <input type="hidden" name="hosted_button_id" value="T65XNLTUDM7N8">
        <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
        <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" b5q177zp9="" width="1" height="1" border="0">
      </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=000674853c33e85a49e53" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Mai 2013<br>
        Collection: MNIMES<br>
        Français<br>
        Traduit du grec par Angela Mikallou-Jourdan et Constantia Yiakoumetti<br>
        <strong>ISBN : 978-2-915936-23-0</strong><br>
        Format : 14x21 cm, 100 pages&nbsp;<br>
        Couverture souple avec rabat<br>
        15 photographies en quadrichromie</p>`,

    price: `17,50 €`
  },

  {
    id: 6,
    thumbnail: `LePas_min.jpg`,
    titleList: `Le pas mal assuré`,
    subtitleList: ``,
    summary: `Ainsi deux nuages s’unissent furtivement, délicatement, puis s’écartent à nouveau l’un de l’autre. Mais parfois ...`,

    cover: `LePas.jpg`,
    titleDetail: `Le pas mal assuré`,
    subtitleDetail: ``,
    authors: `Par Christos Hatzipapas`,
    description: `<p>Ainsi deux nuages s’unissent furtivement, délicatement, puis s’écartent à nouveau l’un de l’autre. Mais parfois leur rencontre brutale provoque tonnerre et éclairs… Ah ! Comme ils dansaient ! ! Leur tournoiement magnétique nous emportait dans des tourbillons de volupté, décrochait de nos artères les athéromes de l’oubli. Sans la moindre retenue, nous nous sentions aussi juvéniles qu’eux. A un moment, la plus jeune des quatre, Massia, emportée par son élan, virevoltante, se retrouva dans mes bras. Je fus comme pris sur le fait. Peut-être parce que je ne cessais de fixer son regard, comme saisi d’ivresse. Ce regard de chatte indocile qui se posait sur moi pour la troisième fois ce jour-là : d’abord dans cet hôtel de misère, puis dans l’antique église, enfin au milieu de la neige fraîche tombée. Probablement prise de vertige elle aussi, poursuivie qu’elle était par je ne sais quoi, je ne sais qui. Et ne sachant pas davantage que …</p>
      <p><b>Christos Hadjipapas</b>, né à Chypre en 1947, a étudié la médecine vétérinaire à Sofia et y a obtenu son master. Il a publié trois romans, quatre recueils de nouvelles et trois de poèmes, et reçu par deux fois le Prix national chypriote de littérature. Ses ouvrages, édités en Grèce, ont été pour certains traduits en bulgare, en turc et en anglais. Christos Hadjipapas a présidé l’Union des écrivains chypriotes.</p>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
      <input type="hidden" name="cmd" value="_s-xclick">
      <input type="hidden" name="hosted_button_id" value="UR6AMNHR27XXG">
      <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
      <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" whry685u6="" width="1" height="1" border="0">
    </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=0006748536693ad8e5748" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Février 2014<br>
      Collection: MNIMES<br>
      Français<br>
      Traduit du grec par Pierre Coavoux<br>
      <strong>ISBN : 978-2-915936-20-9</strong><br>
      Format : 14x21 cm, 225 pages&nbsp;<br>
      Couverture souple avec rabat<br>
      11 photographies en quadrichromie</p>`,

    price: `20 €`
  },

  {
    id: 7,
    thumbnail: `Visages.jpg`,
    titleList: `Visages`,
    subtitleList: ``,
    summary: `Le photographe Michael Amandus Kersten, rend hommage à l’artiste Michael Meschke.`,

    cover: `VisagesBig.jpg`,
    titleDetail: `Visages`,
    subtitleDetail: ``,
    authors: `Par Michael Meschke et Michael Kersten`,
    description: `<p>Regardez et explorez le monde de la Grèce, de cette Hellade tant modelée et métamorphosée au cours des siècles, tant visitée et tant admirée ou critiquée. La magie de la photographie, en un très bref instant, a rendu immortels et éternellement offerts aux yeux de tous ces regards pénétrants et vrais. L’itinéraire de ce livre, proposé comme un voyage rêvé, prend le chemin de la langue du peuple grec qui nous guide, à travers son alphabet, entre l’Alpha et l’Ωméga des poètes, des philosophes et des Dieux.</p>`,

    paypal: `<strong>Epuisé / out of stock / εξαντλημένο</strong>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=00067485367351e65e34f" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1">Parution : Novembre 2008<br>
    Collection : Démiurges<br>
    Français / Grec (Ελληνογαλλικό)<br>
    Traduit du français en grec par M. Koulentianou<br>
    ISBN : 978-2-915936-07-0 <br>
    Format : 21x21 cm,
    206 pages <br>
    Couverture souple avec rabat<br>
    95 photographies en quadrichromie<br>
  </p>`,

    price: `30 €`
  },

  {
    id: 8,
    thumbnail: `Anguera.jpg`,
    titleList: `Jean Anguera`,
    subtitleList: `Sculpteur de l’impalpable`,
    summary: `Cet ouvrage est le résultat de plusieurs années d'amitié entre le poète Salah Stétié et l'artiste Jean Anguera. Avec cet essai ...`,

    cover: `AngueraBig.jpg`,
    titleDetail: `Jean Anguera`,
    subtitleDetail: `Sculpteur de l’impalpable`,
    authors: `Par Salah Stétié`,
    description: `<p><em>La sculpture est, parmi les arts plastiques, le plus paradoxal qui soit.</em></p>
  <p>Il consiste, d'une manière inattendue et comme inespérée, à faire du plein avec du vide et du vide avec du plein. Apparier le vide avec le plein, c'est tout à la fois dégager du non-être originel le signe de la forme et c'est maintenir ce signe dans une manière de tremblement que semble entretenir avec lui son surgissement du néant toujours prêt à le reprendre, à le restituer à l'abîme. Toute sculpture pour le temps qu'elle est là, fulgurant d'une présence négative à nos yeux saisis d'une incrédulité métaphysique, pose, par le fait même qu'elle est palpitation d'existence, la question, immédiate et simultanée, de sa disparition. Elle est parce qu'elle n'est pas et c'est précisément de n'être pas qui, philosophiquement, la comble d'être. Et c'est pourquoi, même quand il lui arrive d'être figurative, même quand elle joue avec le soleil et l'ombre (qui sont, énigmatiquement, des modes d'équivalence avec le plein et le vide), la sculpture, dans son immobilité habituelle, est le plus vivant des arts, tout de modulations et, dans son hiératisme, le plus souple et le plus changeant d'entre eux, à la manière d'une quête de sens toujours exaspérée, toujours fuyante.</p>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
  <input type="hidden" name="cmd" value="_s-xclick">
  <input type="hidden" name="hosted_button_id" value="HNN6H5L6QBD9U">
  <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
  <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" kf6gyl01g="" width="1" height="1" border="0">
</form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=0006748532eadb2ac83ec" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Septembre 2011<br>
  Collection: Démiurges<br>
  Français<br>
  ISBN : 978-2-915936-13-1 <br>
  Format : 21x26 cm, 136 pages <br>
  Couverture rigide avec jaquette<br>
  75 illustrations en couleur</p>`,

    price: `30 €`
  },

  {
    id: 9,
    thumbnail: `Lumiere.jpg`,
    titleList: `Lumière des corps`,
    subtitleList: ``,
    summary: `Ce sont là, toutes, les filles d’une même saison. Or cette saison c’est le printemps, quand le soleil est là, quand ...`,

    cover: `LumiereBig.jpg`,
    titleDetail: `Lumière des corps`,
    subtitleDetail: ``,
    authors: `Photographies d'Emmanuel Ciepka<br>Préface et poèmes de Salah Stétié`,
    description: `<p>Ce sont là, toutes, les filles d’une même saison. Or cette saison c’est le printemps, quand le soleil est là, quand toutes les fleurs du corps sont là…<br>
    <br>
    Nues et tout à la fois voilées de délicate et délicieuse pudeur, dans la suite des pages de ce livre d’images conçu comme un livre d’heures, ces jeunes femmes, plus belles de par leurs différences mêmes, surprises par l’objectif, complices de l’objectif, n’en constituent d’une certaine façon qu’une seule dans le bonheur inentamé d’être et d’exister à travers le rayonnement de son corps. <br>
    <br>
    Emmanuel Ciepka,&nbsp; le photographe, organisateur de cette fête légère de l’œil et peut-être de l’âme, disons d’une résonance de l’âme, sait profiter de cette complicité offerte. Il ne se veut, fond et forme, rien qu’un poète de la douceur et, au-delà des corps, qu’un chantre de leur lumière.</p>`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="TN9J48BYPKWW4">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" sibqv9t7t="" width="1" height="1" border="0">
  </form>`,
    preview: `<iframe src="//v.calameo.com/?bkcode=000674853483f88daba0d" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,

    caracteristics: `<p class="Style1" align="left">Parution : Novembre 2011<br>
    Collection: KalliTechnes<br>
    Français<br>
    ISBN : 978-2-915936-14-8 <br>
    Format : 22,5 x 30 cm, 144 pages <br>
    Couverture rigide avec jaquette<br>
    110 illustrations en bichromie</p>`,

    price: `32 €`
  },

  {
    id: 10,
    thumbnail: `Erotisme.jpg`,
    titleList: `L'érotisme sculpté`,
    subtitleList: ``,
    summary: `La figure humaine est la source principale d’inspiration de l’art grec.`,

    cover: `ErotismeBig.jpg`,
    titleDetail: `L'érotisme sculpté`,
    subtitleDetail: `Esthétique de la sculpture grecque révélée par la photographie`,
    authors: `Par Haris Yiakoumis et Lucie Bonato<br>Préface de Jacques Lacarrière`,
    description: `<p><br>
    <span class="texte"> La figure humaine est la source principale d'inspiration de l'art grec. Si les sculpteurs de la période archaïque s'efforcent de corriger le rendu anatomique, ceux de la période classique étudient l'équilibre du corps debout dont le poids est supporté par une seule jambe. <br>
      <br>
      Les sculptures grecques ont été admirées de façon confidentielle pendant longtemps, ce n'est qu'à la fin du XIXe siècle que les photographes se sont appliqués à révéler leur idéal esthétique et leur érotisme. <br>
      <br>
      Les photographies de ce livre mises en parallèle avec des textes anciens sont un hymne à la beauté et à la sensualité du corps sculpté que les artistes grecs ont su si bien dévoiler. </span> </p>`,

    caracteristics: `<p class="Style1"><br>
  Parution : Mars 2005<br>
  Collection : <em>Album - Théma</em><br>
  Français / Grec (Ελληνογαλλικό)<br>
  (Traduction du grec ancien par A. Karastathi et D. Verlet)<br>
  ISBN : 2-915936-00-5<br>
  Format : 21x23 cm,
  224 pages<br>
  Couverture souple avec rabat<br>
  228 photographies en quadrichromie </p>`,

    price: `38 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
  <div align="center">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="9002105">
    <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
    <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" h27dsbp3l="" width="1" height="1" border="0"> </div>
</form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853244605276f03" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 11,
    thumbnail: `Thessalonique.jpg`,
    titleList: `Thessalonique`,
    subtitleList: ``,
    summary: `Ce livre n’est ni un ouvrage d’histoire, ni un ouvrage de photographie. C’est un genre nouveau qu’on pourrait qualifier de « mythohistorio-photo-gramma ».`,

    cover: `ThessaloniqueBig.jpg`,
    titleDetail: `Thessalonique`,
    subtitleDetail: `Thessalonique à la première personne`,
    authors: `Par Sakis Serefas et Haris Yiakoumis`,
    description: `<p><br>
    <span class="texte"> Ce livre n'est ni un ouvrage d'histoire, ni un ouvrage de photographie. C'est un genre nouveau qu'on pourrait qualifier de "mytho-historio-photo-gramma". <br>
        <br>
        C'est avant tout le carnet d'un voyage à Thessalonique, la ville mythique des Balkans, à travers des images et des impressions réelles, rêvées ou inventées par les auteurs. <br>
        <br>
        Laissez vous emporter par le rêve et voyagez... </span> </p>`,

    caracteristics: `<p class="Style1">Parution : Mai 2005<br>
  Collection : <em>Carnet de voyage rêvé </em><br>
  Français<br>
  (Traduction par Danaé Verlet)<br>
  ISBN : 2-915936-03-X<br>
  Format : 17x24 cm, 192 pages<br>
  Couverture souple avec rabat<br>
  150 photographies en quadrichromie </p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
  <div align="center">
      <input type="hidden" name="cmd" value="_s-xclick">
      <input type="hidden" name="hosted_button_id" value="9002102">
      <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
      <img alt="" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" mu90jikir="" width="1" height="1" border="0"> </div>
</form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=00067485314adc2c4e3c4" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 12,
    thumbnail: `MykonosDelos.jpg`,
    titleList: `Mykonos et Délos`,
    subtitleList: ``,
    summary: `Un voyage initiatique au coeur de la mer Égée.`,

    cover: `MykonosDelosBig.jpg`,
    titleDetail: `Mykonos et Délos`,
    subtitleDetail: `à l’aube du XXᵉ siècle`,
    authors: `Par Lucie Bonato et Haris Yiakoumis`,
    description: `<p><br>
    <span class="texte"> Ce livre est un voyage initiatique au cœur de la mer Égée. La magie de la photographie nous transporte à l'aube du xxe siècle et les vues panoramiques invitent à flâner dans Mykonos avec ses moulins, ses maisons blanches et sa flottille de caïques. <br>
      <br>
      Après une excursion à Dragonisi, nous visitons l'île mythique de Délos alors que les découvertes archéologiques de l'Ecole française d'Athènes font revivre le sanctuaire d'Apollon. <br>
      <br>
      Laissez-vous emporter par le rêve et voyagez... </span> </p>`,

    caracteristics: `<p class="Style1">Parution : Septembre 2005 <br>
  Collection : <em>Carnet de voyage rêvé </em>Français<br>
  ISBN : 2-915936-04-8<br>
  Format : 24x17 cm, 192 pages<br>
  Couverture souple avec rabat<br>
  207 photographies en quadrichromie </p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
  <div align="center">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="9002095">
    <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
    <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" iqo6puyip="" width="1" height="1" border="0"> </div>
</form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853b737b20e9395" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 13,
    thumbnail: `Crete.jpg`,
    titleList: `Crète 1898 - 1899`,
    subtitleList: `Témoignages photographiques à travers l’album personnel du Prince Georges`,
    summary: `Les photographies publiées dans ce volume proviennent de l’album personnel du prince Georges, premier...`,

    cover: `CreteBig.jpg`,
    titleDetail: `Crète 1898 - 1899`,
    subtitleDetail: `Témoignages photographiques à travers l’album personnel du Prince Georges`,
    authors: `Par Haris Yiakoumis, Ioannis Kokkinakis et Irini Lydakis`,
    description: `<p>Les photographies publiées dans ce volume proviennent de l’album personnel du prince Georges, premier Haut Commissaire de Crète lors de la période de l’indépendance (1898-1913). L’album comprend des photographies affichant des moments de la vie publique et privée de Georges en Crète lors des premiers mois de son mandat, tels qu’ils furent captés par l’appareil photographique de Périclès Diamandopoulos, son photographe officiel, ou parfois par Georges lui-même ou les membres de sa suite. </p>`,

    caracteristics: `<p class="Style1">Parution : Décembre 2009<br>
    Textes trilingue : Grec, français, Anglais<br>
    ISBN : 978-2-915936-09-4 <br>
    Format : 24,5 x 29,5 cm , 336 pages <br>
    Couverture souple avec rabat <br>
    193 photographies en quadrichromie</p>`,

    price: `55 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="10454957">
    <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus s�curis�e !" border="0">
    <img alt="" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" xa88f39k7="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853cb3235c43e3b" seamless="seamless" allowtransparency="true" width="100%" height="500" frameborder="0"></iframe>`,
  },

  {
    id: 14,
    thumbnail: `RegardsDeGrece.jpg`,
    titleList: `Regards de Grèce`,
    subtitleList: `du XIXe au XXe siècle`,
    summary: `L’itinéraire de ce livre, proposé comme un voyage rêvé, prend le chemin de la langue du peuple grec.`,

    cover: `RegardsDeGrece_big.jpg`,
    titleDetail: `Regards de Grèce`,
    subtitleDetail: `du XIXe au XXe siècle`,
    authors: `Par Manos Eleutheriou et Haris Yiakoumis`,
    description: `<p>Regardez et explorez le monde de la Grèce, de cette Hellade tant modelée et métamorphosée au cours des siècles, tant visitée et tant admirée ou critiquée. La magie de la photographie, en un très bref instant, a rendu immortels et éternellement offerts aux yeux de tous ces regards pénétrants et vrais. L’itinéraire de ce livre, proposé comme un voyage rêvé, prend le chemin de la langue du peuple grec qui nous guide, à travers son alphabet, entre l’Alpha et l’Ωméga des poètes, des philosophes et des Dieux.</p>`,

    caracteristics: `<p class="Style1">Parution : Novembre 2008 <br>
    Collection : <em>d'Alpha à Ωméga</em><br>
    Français<br>
    (Traduit du grec par C. Yiakoumetti et N. Kita) <br>
    ISBN : 978-2-915936-08-7<br>
    Format : 21x27,5 cm,
    336 pages<br>
    Couverture rigide avec jaquette<br>
    300 photographies en quadrichromie </p>`,

    price: `45 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="9002029">
    <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus s�curis�e !" border="0">
    <img alt="" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" wpfu9ffbi="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=00067485317ea2389fb3c" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 15,
    thumbnail: `Chypre.jpg`,
    titleList: `L'île de Chypre`,
    subtitleList: ``,
    summary: `Au début du XIXe siècle, l’île de Chypre n’est bien souvent qu’une simple escale vers l’Orient.`,

    cover: `ChypreBig.jpg`,
    titleDetail: `L'île de Chypre`,
    subtitleDetail: `Itinéraire photographique du XIXe au XXe siècle`,
    authors: `par Lucie Bonato, Haris Yiakoumis et Kadir Kabba`,
    description: `<span class="texte"> Au début du XIXème siècle, l'île de Chypre n'est bien souvent qu'une simple escale vers l'Orient. Elle est mal connue, certains voyageurs se contentant même de regarder la côte depuis le pont du navire lorsqu'ils touchent à Larnaca. Puis, tout au long du siècle, explorateurs, érudits, savants et enfin "touristes" foulent le sol de l'île et découvrent avec enthousiasme ses merveilles : la ville morte de Famagouste, Nicosie dont les Lusignan ont fait leur capitale, le charmant petit port de Kérynia, Limassol près de laquelle débarqua Richard Cœur de Lion, ou bien encore Paphos dont le nom seul suffit pour évoquer Aphrodite, la plus belle des déesses. Les photographies anciennes réunies dans cet ouvrage sont une invitation à la découverte du charme insolite de Chypre et de ses habitants en compagnie des voyageurs de la fin du xixe et du début du xxe siècle. <br>
    <br>
    Laissez-vous emporter par le rêve et voyagez... </span>`,

    caracteristics: `<p class="Style1">Parution : Novembre 2006 <br>
    Collection : <em>Carnet de voyage rêvé <br>
    </em>Français<br>
    ISBN : 2-915936-01-3<br>
    Format : 17x24 cm, 240 pages<br>
    Couverture souple avec rabat<br>
    224 photographies en quadrichromie </p>`,

    price: `35 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <div align="center">
      <input type="hidden" name="cmd" value="_s-xclick">
      <input type="hidden" name="hosted_button_id" value="9002094">
      <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
      <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" mne42g9ps="" width="1" height="1" border="0"> </div>
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=00067485314ea86e43a8b" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 16,
    thumbnail: `ChyprePano.jpg`,
    titleList: `Chypre panoramique, voyage au pays d’Aphrodite`,
    subtitleList: `du XIXe au XXe siècle`,
    summary: `Chypre est l’île d’Aphrodite. C’est là que la légende l’a fait naître de l’écume de la mer, c’est là que ...`,

    cover: `ChyprePanoBig.jpg`,
    titleDetail: `Chypre panoramique, voyage au pays d’Aphrodite`,
    subtitleDetail: `voyage au pays d'Aphrodite du XIXe au XXe siècle`,
    authors: `Par Lucie Bonato, Jacqueline Karageorghis, Haris Yiakoumis`,
    description: `<p>Chypre est l’île d’Aphrodite. C’est là que la légende l’a fait naître de l’écume de la mer, c’est là que se trouvait son temple le plus célèbre. Quelle était cette île dans la deuxième moitié du XIXe et au début du XXe siècle ? Était-elle recouverte de frais bosquets, telle que l’avaient décrite les auteurs de l’antiquité, ou bien décevante comme le notaient les voyageurs l’observant depuis les navires lors d’une brève escale ? </p><p>La réponse est dans ce livre. Il suffit de regarder les œuvres des photographes, professionnels ou amateurs. Au fil des pages, elles révèlent une île magnifique, surprenante et unique de par ses monuments, ses paysages, ses habitants. Cette île séduira le lecteur entraîné dans un extraordinaire voyage au pays de la déesse de la beauté et de l’amour chantée par les poètes.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Mars 2011<br>
    Collection: Pan-Orama<br>
    Français / Grec (Eλληνογαλλικό)<br>
    (Traduction du français en grec par <br>
    Catherine Dourida)<br>
    ISBN : 978-2-915936-12-4 <br>
    Format : 24x34 cm, 272 pages <br>
    Couverture rigide avec jaquette<br>
    200 photographies en couleur</p>`,

    price: `49 €`,

    paypal: `<strong>Epuisé / out of stock / εξαντλημένο</strong>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853a8acfad1fb69" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 17,
    thumbnail: `Constantinople.jpg`,
    titleList: `Constantinople 1900`,
    subtitleList: `Journal photographique de T. Wild`,
    summary: `Constantinople 1900 est la publication d’un album photographique inédit de T. Wild, voyageur...`,

    cover: `ConstantinopleBig.jpg`,
    titleDetail: `Constantinople 1900`,
    subtitleDetail: `Journal photographique de T. Wild`,
    authors: `Collection : Pierre de Gigord<br>Par Jean-François Pérouse`,
    description: `<p><em>Constantinople 1900</em> est la publication d’un album photographique inédit de T. Wild, voyageur
    britannique, par ailleurs inconnu. L’essentiel des photographies concernent Constantinople mais
    le voyage commence à Douvres, se poursuit par Bruxelles, Vienne, la Roumanie, la Bulgarie, le
    retour se faisant par Naples, Rome, Monaco, Nice. Les images collationnées sont exceptionnelles
    par leur format – la plupart sont des panoramas d’excellente qualité – mais aussi par le sujet
    traité : les populations de Constantinople <em>in vivo</em>. Et les monuments, tant photographiés par
    ailleurs, relégués à l’arrière plan, sont ici devenus décor. En ce début de XXe siècle, T. Wild a su
    montrer non seulement combien Constantinople est cosmopolite, vivante, bourdonnante à souhait,
    avec les incessants mouvements des bateaux et des habitants, mais aussi que pour orientale qu’elle
    est, la capitale ottomane est aussi tellement occidentale. Les textes de Jean-François Pérouse, dans
    l’esprit de l’époque, à la suite de Loti, Gautier et bien d’autres, viennent accompagner les images
    pour faire de cet ouvrage un carnet de voyage véritablement « rêvé ».</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Octobre 2010<br>
    Collection: Historiographies <br>
    Français / Grec (Eλληνογαλλικό)<br>
    (Traduction du français en grec par <br>
    Constantia Yiakoumetti)<br>
    ISBN : 978-2-915936-10-0 <br>
    Format : 30x24 cm, 256 pages <br>
    Couverture rigide avec jaquette<br>
    190 photographies en couleur</p>`,

    price: `40 €`,

    paypal: `<form style="text-align:center" target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="WSW9EX6459YX6">
    <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus s�curis�e !" border="0">
    <img alt="" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" a3x9eniwo="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853b522ee0e3192" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 18,
    thumbnail: `PhoceeSacDvd.jpg`,
    titleList: `Félix Sartiaux`,
    subtitleList: `Le sac de Phocée`,
    summary: `À sept heures du soir, tout est fini. La catastrophe est accomplie. L’opération a duré ...`,

    cover: `PhoceeSacDvd_Big.jpg`,
    titleDetail: `<p><strong>Félix Sartiaux, <em>Le sac de Phocée et l’expulsion des Grecs ottomans d’Asie-Mineure en juin 1914.</em></strong><br>
    <strong>Φελίξ Σαρτιώ, <em>Η καταστροφή της Φώκαιας και ο διωγμός των Ελλήνων οθωμανών από τη Μικρά Ασία τον Ιούνιο του 1914.</em></strong><br>
  </p>`,
    subtitleDetail: ``,
    authors: `Traduction et Postface Anastasia Carastathi<br>Μετάφραση και Επίμετρο Αναστασία Καραστάθη`,
    description: `<p>Accompagné d’un DVD du film : <b>1914 Événements de Phocée</b> d’Agnès Skalvou et de Stelios Tatakis / Συνοδεύετε από το DVD του φιλμ: <b>1914 Γεγονότα στη Φώκαια</b> της Ανιες Σκλάβου και του Στέλιου Τατάκη.</p>
    <p>À sept heures du soir, tout est fini. La catastrophe est accomplie. L’opération a duré à peine vingt-quatre heures. Les organisateurs doivent être satisfaits ! Le coup a été bien monté, pas un accident ne s’est produit en d’exécution : aucun Turc n’a été même blessé ; l’entreprise a été menée de main de maître. Ces nouveaux Barbares n’ont rien à envier à leurs ancêtres, aux hordes ottomanes, qui ravageaient au moyen âge les côtes et les îles !</p>
    <p>Στις επτά το βράδυ όλα έχουν τελειώσει. Η καταστροφή έχει ολοκληρωθεί. Η επιχείρηση κράτησε μετά βίας είκοσι τέσσερις ώρες. Οι οργανωτές πρέπει να είναι ικανοποιημένοι! Το εγχείρημα είχε στηθεί καλά, ούτε μια στραβοτιμονιά κατά την εκτέλεση, ούτε καν ένας Τούρκος πληγωμένος. Όλα έγιναν αριστοτεχνικά. Οι νέοι αυτοί βάρβαροι δεν έχουν τίποτα να ζηλέψουν από τους προγόνους τους, τις οθωμανικές ορδές, που ρήμαζαν κατά τον Μεσαίωνα τις ακτές και τα νησιά.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Juin 2014<br>
    Collection: PHOTO-HISTORIA<br>
    Français et Grec<br>
    <strong>ISBN : 978-2-915936-24-7</strong><br>
    Format : 21x27,5 cm, 90 pages&nbsp;<br>
    Couverture souple<br>
    5 photographies en couleur</p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="V9SMNQAWFSJDW">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" kj124164x="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=00067485314bcbd0c13d7" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 19,
    thumbnail: `RegardsPhoceens.jpg`,
    titleList: `Regards Phocéens`,
    subtitleList: ``,
    summary: `Félix Sartiaux (1876 - 1944), archéologue et ingénieur français, fut le principal témoin de la persécution des habitants grecs de l’Ancienne ...`,

    cover: `RegardsPhoceensBig.jpg`,
    titleDetail: `Regards Phocéens`,
    subtitleDetail: `de Félix Sartiaux`,
    authors: `Par Haris Yiakoumis et Nicolaos Chorbos<br>Collection : Pierre de Gigord`,
    description: `<p>Félix Sartiaux (1876 - 1944), archéologue et ingénieur français, fut le principal témoin de la persécution des habitants grecs de l’Ancienne et de la Nouvelle Phocée en Asie Mineure,
    en juin 1914.
    Dans ce livre, la publication des patronymes phocéens en regard des nouvelles photographies du protagoniste de l’archéologie et de l’histoire phocéennes, Félix Sartiaux, nous a semblé la meilleure façon d’honorer ce peuple.
    Ces noms de famille, légués de génération en génération, constituent en quelque sorte une patrie immatérielle dont personne ne peut les priver. </p><p>Ο Φελίξ Σαρτιώ (1876 - 1944), Γάλλος αρχαιολόγος και μηχανικός, αποτέλεσε το βασικότερο μάρτυρα των γεγονότων του διωγμού που είχαν υποστεί οι Έλληνες κάτοικοι της Παλαιάς και
    της Νέας Φώκαιας της Μικράς Ασίας, τον Ιούνιο του 1914.
    Σ’ αυτό το βιβλίο, η δημοσίευση των φωκαϊκών ονομάτων, παράλληλα με τις νέες φωτογραφικές λήψεις / μαρτυρίες του Φελίξ Σαρτιώ, πρωταγωνιστή της φωκαϊκής αρχαιολογίας και ιστορίας, μας φάνηκε ο καλύτερος τρόπος να τιμηθεί ο φωκαϊκός λαός.
    Αυτά τα πατρώνυμα, που από γενιά σε γενιά οι Φωκιανοί μεταφέρουν, αποτελούν μια άλλου είδους πατρίδα την οποία δεν μπορεί κανείς να τους αφαιρέσει. </p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Août 2012<br>
    Collection: <em>PHOTO-HISTORIA</em><br>
    Français et Grec / Γαλλικά και Ελληνικά<br>
    <br>
    ISBN : 978-2-915936-19-3<br>
    Format : 21x27,5 cm, 176 pages<br>
    Couverture souple avec rabat<br>
    180 photographies en quadrichromie</p>`,

    price: `30 €`,

    paypal: `<b>Epuisé / out of stock / εξαντλημένο</b>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=0006748535b51add2a897" seamless="seamless" allowtransparency="true" width="100%" height="500" frameborder="0"></iframe>`,
  },

  {
    id: 20,
    thumbnail: `Phocee.jpg`,
    titleList: `Phocée 1913 - 1920`,
    subtitleList: ``,
    summary: `Félix Sartiaux (1876-1944), ingénieur et archéologue, fut chargé par le gouvernement français de plusieurs missions de fouilles sur le site de l’Ancienne Phocée.`,

    cover: `PhoceeBig.jpg`,
    titleDetail: `Phocée 1913 - 1920`,
    subtitleDetail: `Le témoignage de Félix Sartiaux`,
    authors: `Edité par Haris Yiakoumis<br>
    avec la collaboration d’Antoine Hermary<br>
    Manolis Tsalikidis et Nicolaos Chorbos</p>`,
    description: `<p>Félix Sartiaux (1876-1944), ingénieur et archéologue, fut chargé par le gouvernement français de plusieurs missions de fouilles sur le site de l’Ancienne Phocée (Asie Mineure). Il y dirigea trois campagnes, en 1913, 1914 et 1920. En juin 1914, il fut non seulement témoin des événements tragiques qui conduisirent à l’expulsion des Phocéens grecs de leur patrie mais il y prit une part active : avec ses compagnons, il hissa les couleurs de la France sur quatre maisons, plaçant sous la protection française un grand nombre de Phocéens qu’il réussit ainsi à sauver. Par la suite, il chercha à faire rendre justice à cette population et pour cela, publia des textes sur les événements et donna des conférences. En 1919, les Phocéens regagnent leur ville, Sartiaux les accompagne. Il poursuivit ses travaux tout en immortalisant leurs moments de joie et de peine. Sartiaux ne se départit jamais du soutien qu’il avait accordé à ce peuple et consacra l’essentiel de ses œuvres à l’Asie Mineure, et en particulier à Phocée.</p>`,

    caracteristics: `<span class="Style1">Parution : Juillet 2008<br>
    Collection : <em>Historiographies<br>
    </em>Français / Grec (Ελληνογαλλικό)<br>
    (Traduction du français en grec par A. Karastathi)<br>
    ISBN : 978-2-915936-06-3<br>
    Format : 24x29 cm, 320 pages<br>
    Couverture rigide avec jaquette <br>
    216 photographies en bichromie <br>
  </span>`,

    price: `55 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <div align="center">
      <input type="hidden" name="cmd" value="_s-xclick">
      <input type="hidden" name="hosted_button_id" value="9002086">
      <input type="image" src="https://www.paypal.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="" border="0">
      <img alt="Ajouter au panier" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" wsd8e21nk="" width="1" height="1" border="0"> </div>
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853a334852d12e3" seamless="seamless" allowtransparency="true" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 21,
    thumbnail: `MetamorphosesAthenes_min.jpg`,
    titleList: `Μεταμορφώσεις των Αθηνών`,
    subtitleList: ``,
    summary: `Η νεότερη Αθήνα αποτελεί ιδιαίτερη περίπτωση ως προς την πολεοδομική και την οικιστική της ανάπτυξη. Η ιστορία ...`,

    cover: `MetamorphosesAthenes.jpg`,
    titleDetail: `Μεταμορφώσεις των Αθηνών`,
    subtitleDetail: `Φωτογραφικό οδοιπορικό από τον 19ο στον 20ό αιώνα`,
    authors: `Par Haris Yiakoumis et Tasos A. Andreou / του Χάρη Γιακουμή και Τάσου Α. Ανδρέου`,
    description: `<p>Η νεότερη Αθήνα αποτελεί ιδιαίτερη περίπτωση ως προς την πολεοδομική και την οικιστική της ανάπτυξη. Η ιστορία τη σεβάστηκε και δεν γνώρισε δραματικές καταστροφές από σεισμούς ή πολεμικές αναμετρήσεις, όπως τόσες άλλες πόλεις εντός ή εκτός της ελληνικής επικράτειας. Κάποιες γειτονιές γλίτωσαν από τη συστηματική ανοικοδόμηση και αποτελούν σήμερα ανάσες στον πυκνό πολεοδομικό ιστό της πόλης.</p><p>Φιλοδοξία του βιβλίου είναι η ανασύνθεση των παλαιότερων εικόνων της πόλης των Αθηνών κατά την περίοδο από τα μέσα του 19ου αιώνα έως και τα πρώτα μεταπολεμικά χρόνια με τη βοήθεια σπάνιου φωτογραφικού υλικού. Πολλά εμβληματικά κτίρια της, εκτοπισμένα προ πολλού από το κέντρο του ενδιαφέροντος, διεκδικούν τώρα μια θέση στη μνήμη των αναγνωστών του και τους βοηθούν να αναπαραστήσουν νοερά την πρότερη μορφή της πόλης, όπως την έζησαν οι παλαιότεροι κάτοικοι και επισκέπτες της.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Octobre 2014<br>
    Collection: PHOTO-HISTORIA<br>
    Grec<br>
    <strong>ISBN : 978-2-915936-25-4</strong><br>
    Format : 24x28 cm, 176 pages&nbsp;<br>
    Couverture souple avec rabat<br>
    250 photographies en quadrichromie</p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="GG3VJ7DG7GJ66">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" fa257bqix="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853f95b261e6584" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 22,
    thumbnail: `24.jpg`,
    titleList: `Metamorphoses of Athens`,
    subtitleList: ``,
    summary: `Modern Athens is a particular example of urban development. History showed a certain respect, ...`,

    cover: `24.jpg`,
    titleDetail: `Metamorphoses of Athens`,
    subtitleDetail: `A photographic journey from the 19th to the 20th century`,
    authors: `Par Haris Yiakoumis, Tasos A. Andreou`,
    description: `<p>Modern Athens is a particular example of urban development. History showed a certain respect, and the city did not sustain destruction from earthquakes or battles, as did many other cities both within and outside Greece. Some neighborhoods escaped systematic reconstruction and today constitute a breath of fresh air in the dense urban web of the city.</p><p> This book aspires to reassemble, with the help of rare photographic material, the oldest images of the city of Athens from the mid-19th century to the first years following WWII. Many iconic Athenian buildings, long since displaced from the center of interest, now claim a place in the memory of the readers and help them to mentally reproduce the older form of the city, as its inhabitants and its visitors experienced it in the past.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Mars 2015<br>
    Collection: PHOTO-HISTORIA<br>
    Langue: Anglais<br>
    <strong>ISBN : 978-2-915936-26-1</strong><br>
    Format : 24x28 cm, 176 pages&nbsp;<br>
    Couverture souple avec rabat<br><br>
    250 photographies en couleur
  </p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="2HGABWX579UVA">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" cgta0mk6d="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=0006748536a71f992e85c" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="970" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 23,
    thumbnail: `22.jpg`,
    titleList: `Πρόσωπα των Αθηνών`,
    subtitleList: ``,
    summary: `Η ιστορία χρειάζεται τη μνήμη για να μπορέσει να υπάρξει και η μνήμη τις εικόνες και τα γεγονότα για να μπορέσει να διηγηθεί. Μέσα από ...`,

    cover: `22.jpg`,
    titleDetail: `Πρόσωπα των Αθηνών`,
    subtitleDetail: `από τον 19ο στον 20ό αιώνα, 1855–1960`,
    authors: `ΧΆΡΗΣ ΓΙΑΚΟΥΜΉΣ, ΒΑΣΙΛΙΚΉ ΜΑΥΡΟΕΙΔΑΚΟΥ`,
    description: `<p>Η ιστορία χρειάζεται τη μνήμη για να μπορέσει να υπάρξει και η μνήμη τις εικόνες και τα γεγονότα για να μπορέσει να διηγηθεί. Μέσα από διακόσιες και πλέον φωτογραφίες της παλιάς Αθήνας όχι μόνο ανακαλύπτουμε τη μορφή μιας πόλης διαφορετικής αλλά συναντάμε και τους ανθρώπους της. </p><p> Οι ματιές στα Πρόσωπα των Αθηνών κατευθύνθηκαν πριν χρόνια για πρώτη φορά προς τον φακό και με τρόπο μαγικό το βλέμμα τους έφτασε ώς το σήμερα για να συναντήσει το δικό μας μέσα στις σελίδες του βιβλίου αυτού.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Mai 2015<br>
    Collection: PHOTO-HISTORIA<br>
    Langue&nbsp;: Grec / Ελληνικά<br>
    <strong>ISBN : 978-2-915936-24-7</strong><br>
    Format : 24x22 cm, 132 pages&nbsp;<br>
    Couverture souple avec rabat<br>
  </p><p class="Style1" align="left">200 photographies en quadrichromie <br>
  Συνοδεύετε με ένα DVD vidéo 41΄ / accompagné d’un DVD vidéo (durée 41min)</p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="LBV9Z3MHPBS2E">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" gmv5ivmof="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853a6cb17455602" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="100%" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 24,
    thumbnail: `23.jpg`,
    titleList: `Visages d’Athènes`,
    subtitleList: ``,
    summary: `L’Histoire a besoin de la mémoire pour exister et la mémoire a besoin d’images et d’événements pour raconter. Deux cents ...`,

    cover: `23.jpg`,
    titleDetail: `Visages d'Athènes`,
    subtitleDetail: `du XIXe au XXe siècle, 1855–1960`,
    authors: `HARIS YIAKOUMIS, VASILIKI MAVROIDAKOU`,
    description: `<p>L’Histoire a besoin de la mémoire pour exister et la mémoire a besoin d’images et d’événements pour raconter. Deux cents photographies de la vieille ville d’Athènes nous aident non seulement à découvrir l’aspect d’une cité différente mais aussi à rencontrer ses habitants. Dans Visages d’Athènes, les regards qui ont fixé pour la première fois l’objectif des années auparavant peuvent, comme par magie, rencontrer aujourd’hui le nôtre dans les pages de ce livre.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Mai 2015<br>
    Collection: PHOTO-HISTORIA<br>
    Langue: Français<br>
    <strong>ISBN : 978-2-915936-27-8</strong><br>
    Format : 24x22 cm, 132 pages&nbsp;<br>
    Couverture souple avec rabat<br>
  </p><p class="Style1" align="left">200 photographies en quadrichromie accompagné d’un DVD vidéo (durée 41min)</p>`,

    price: `30 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="57PVGUNXYBWAE">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" spwr5oups="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=0006748538674fe62a7cd" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="970" height="650" frameborder="0"></iframe>`,
  },

  {
    id: 25,
    thumbnail: `25.jpg`,
    titleList: `La Fondation Hellénique`,
    subtitleList: ``,
    summary: `Œuvre financée par une collecte panhellénique dans le contexte difficile de l’entre-deux-guerres, portée avec ...`,

    cover: `25.jpg`,
    titleDetail: `La Fondation Hellénique`,
    subtitleDetail: `de la Cité Internationale de Paris`,
    authors: `Lieu de vie, lieu de mémoire<br><br>
    Sous la direction de Maria Gravari-Barbas`,
    description: `<p>Œuvre financée par une collecte panhellénique dans le contexte difficile de l’entre-deux-guerres, portée avec détermination par le ministre de la Grèce en France, Nikolaos Politis, répondant à l’appel visionnaire du sénateur André Honnorat, la Fondation Hellénique déploie depuis plus de huit décennies son architecture imposante dans le parc de la Cité Universitaire et permet à ses résidents d’étudier et de créer dans cette « Utopie réalisée », lieu de brassage de jeunes qui viennent du monde entier.</p><p> Les philosophes Axelos et Castoriadis, les cinéastes Manos Zacharias, Théo Angelopoulos et Costa Gavras, les artistes Costantin Andreou, Pavlos, Panagiotis Tetsis, Memos Makris, Dimitris Mytaras, Constantin
    Vyzantios, les intellectuels Emmanouil Kriaras, Panagiotis Moullas, Kostas Vergopoulos, le musicien Giorgos Couroupos, les architectes Aristidis Zizikas et Aristomenis Proveleghios, sont quelques-unes des nombreuses personnalités des lettres, des sciences et des arts qui ont résidé à la Fondation Hellénique et à la Cité Internationale Universitaire de Paris.</p><p>Cet ouvrage aborde la Fondation Hellénique dans sa double dimension, matérielle et immatérielle : comme un patrimoine architectural de premier ordre dans lequel ont été écrites des pages importantes de l’histoire de la Grèce.</p>`,

    caracteristics: `<p class="Style1" align="left">Parution : Décembre 2015<br>
    Langue: Français<br>
    <strong>ISBN : 978-2-915936-21-6</strong><br>
    Format : 23,5x30 cm, 240 pages<br>
    Couverture souple avec rabat<br>
  </p><p class="Style1" align="left">220 photographies en quadrichromie</p>`,

    price: `40 €`,

    paypal: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input type="hidden" name="hosted_button_id" value="ECSD54N8JLB4G">
    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_cart_LG.gif" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne" border="0">
    <img alt="" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" xqido0dsx="" width="1" height="1" border="0">
  </form>`,

    preview: `<iframe src="//v.calameo.com/?bkcode=000674853ddafc01f2caf" scrolling="no" allowtransparency="" allowfullscreen="" style="margin:0 auto;" width="100%" height="650" frameborder="0"></iframe>`,
  },
];